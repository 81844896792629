<template>
    <!-- content -->
    <div class="container-fluid bg-lightblue">
        <div class="row">
            <div class="col-12">
                <div class="container py-5">
                    <div class="row mb-4">
                        <div class="col-12">
                            <span class="d-block text-center mp-rbk mp-font-weight-semi-bold font-size-expert-subscription-title">Experten-Abo bestellen</span>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12 col-lg-6">
                            <!-- left side -->
                            <div class="container px-0 px-lg-3">
                                <div class="row mb-4">
                                    <div class="col-12 bg-white form-shadow-light form-rounded-left form-rounded-right p-4">
                                        <span class="d-block mb-3 mp-rbk mp-font-weight-medium font-size-registration-expert-form-section-title">Ihre Bestellung</span>
                                        <label for="order-subscription-type" class="form-label mp-rbk font-size-registration-expert-form-item-label mp-font-weight-regular">PAKETABO</label>

                                        <select class="form-select mb-3" id="order-subscription-type" aria-label="Land" v-model="selectedId">
                                            <option :value="s.id" v-for="s in _subscriptionOptions" :key="`${keyPrefix}_id_${s.id}`">{{ s.label }}</option>
                                        </select>
                                        <span class="mp-rbk font-size-registration-expert-form-addon">Zahlung: {{ selectedSubscription.labelPaymentPlan }}</span>
                                    </div>
                                </div>
                                <div class="row mb-4">
                                    <div class="col-12 bg-white form-shadow-light form-rounded-left form-rounded-right p-4">
                                        <span class="d-block mb-3 mp-rbk mp-font-weight-medium font-size-registration-expert-form-section-title">Gutschein einlösen</span>
                                        <label for="order-voucher" class="form-label mp-rbk font-size-registration-expert-form-item-label mp-font-weight-regular">GUTSCHEINCODE</label>
                                        <input type="text" class="form-control mb-3" placeholder="Gutscheincode eingeben" id="order-voucher">
                                    </div>
                                </div>
                                <div class="row mb-4">
                                    <div class="col-12 bg-white form-shadow-light form-rounded-left form-rounded-right p-4">
                                        <span class="d-block mb-3 mp-rbk mp-font-weight-medium font-size-registration-expert-form-section-title">Zahlungsart auswählen</span>
                                        <div class="form-check">
                                            <input class="form-check-input" type="radio" name="payment-option" id="payment-option-sofort">
                                            <label class="form-check-label" for="payment-option-sofort">
                                                Sofortüberweisung (Klarna)
                                            </label>
                                        </div>
                                        <div class="form-check">
                                            <input class="form-check-input" type="radio" name="payment-option" id="payment-option-cc">
                                            <label class="form-check-label" for="payment-option-cc">
                                                Kredit- & Debitkarte <i class="fab fa-cc-visa"></i> <i class="fab fa-cc-mastercard"></i>
                                            </label>
                                        </div>
                                        <div class="form-check">
                                            <input class="form-check-input" type="radio" name="payment-option" id="payment-option-paypal">
                                            <label class="form-check-label" for="payment-option-paypal">
                                                PayPal
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!-- /left side -->
                        </div>
                        <div class="col-12 col-lg-6">
                            <!-- right side -->
                            <div class="row mb-4">
                                <div class="col-12 bg-white form-shadow-light form-rounded-left form-rounded-right p-4">
                                    <span class="d-block mb-3 mp-rbk mp-font-weight-medium font-size-registration-expert-form-section-title">Bestellübersicht</span>
                                    <div class="row">
                                        <div class="col-8">
                                            <span class="mp-qks font-size-order-overview-item">{{ selectedSubscription.title }}</span>
                                        </div>
                                        <div class="col-4 d-flex justify-content-end">
                                            <span class="mp-qks font-size-order-overview-item">&euro; {{ new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(selectedSubscription.price) }}</span>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-8">
                                            <span class="mp-qks font-size-order-overview-item">abzüglich Gutschein</span>
                                        </div>
                                        <div class="col-4 d-flex justify-content-end">
                                            <span class="mp-qks font-size-order-overview-item">- &euro; 0,00</span>
                                        </div>
                                    </div>
                                    <hr />
                                    <div class="row mb-3">
                                        <div class="col-8">
                                            <span class="mp-qks font-size-order-overview-item mp-font-weight-semi-bold">Gesamtpreis</span>
                                        </div>
                                        <div class="col-4 d-flex justify-content-end">
                                            <span class="mp-qks font-size-order-overview-item mp-font-weight-semi-bold">{{new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(selectedSubscription.price) }}</span>
                                        </div>
                                    </div>
                                    <span class="mp-rbk font-size-registration-expert-form-addon">Oben genannte Preise verstehen sich exklusive Mehrwertsteuer</span>
                                </div>
                            </div>
                            <div class="row mb-4">
                                <div class="col-12">
                                    <div class="form-check">
                                        <input class="form-check-input" type="checkbox" value="" id="order-accept-gdpr">
                                        <label class="form-check-label mp-qks font-size-registration-expert-checkboxes mp-font-weight-medium" for="order-accept-gdpr">
                                            Mit meiner Bestellung erkläre ich mich mit den <a class="registration-expert-links" href="#">Allgemeinen Geschäftsbedingungen</a>, <a class="registration-expert-links" href="#">Widerrufsbestimmungen</a> und <a class="registration-expert-links" href="#">Datenschutzbestimmungen</a> einverstanden.
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <div class="row mb-4">
                                <div class="col-12 col-lg-6 offset-lg-6 d-flex flex-column justify-content-lg-end mb-3">
                                    <button type="button" class="btn btn-mp-green font-size-registration-expert-btn-submit-registration mp-font-weight-medium py-2 px-5">Jetzt bestellen</button>
                                </div>
                            </div>
                            <!-- right side -->
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- /content -->
</template>



<script>
    import {mixinBasics} from "../../mixins/basics";


    export default {
        name: "Subscription",

        mixins: [
            mixinBasics
        ],

        props: {
            id: {
                required: false,
            }
        },

        data() {
            return {
                keyPrefix: 'subscr',
                selectedId: 1
            };
        },

        computed: {
            selectedSubscription() {
                return this._subscriptionOptions.find(s => s.id === this.selectedId);
            }
        },

        methods: {

        },

        mounted() {
            if (this.id) {
                this.selectedId = parseInt(this.id);
            }
            window.scrollTo(0,0);
        }
    }
</script>



<style scoped>

</style>